var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _vm.isShow && _vm.isFinal
        ? [
            _c("FinalReviewContract", {
              attrs: {
                docs: _vm.attentedDocs,
                onBackClicked: _vm.onBackClicked
              }
            })
          ]
        : _vm.isShow && !_vm.isFinal
        ? [
            _c("UploadRequiredDocuments", {
              attrs: {
                docs: _vm.docs,
                onFileUploaded: _vm.onFileUploaded,
                remove: _vm.remove,
                goNextPageConfirm: _vm.goNextPageConfirm,
                goNextPage: _vm.goNextPage,
                showUploadErrorDialog: _vm.showUploadErrorDialog,
                uploadErrorMessage: _vm.uploadErrorMessage,
                showConfirmDialog: _vm.showConfirmDialog,
                closeUploadErrorDialog: _vm.closeUploadErrorDialog,
                closeConfirmDialog: _vm.closeConfirmDialog
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }